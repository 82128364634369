const url = '/api/v2/widget/'


export const WidgetApiUrls = {
    // booking-module получить от сертификата
    async getCertificate(){
        return (await window.axios.get(url+'payment/')).data
    },
    // booking-module загрузить сертификат
    async setCertificate(formdata, jwt){
        return (await window.axios.post(url+'payment/', formdata, {headers: {Authorization: `${jwt}`}})).data
    },
    // booking-module получить colors компании
    async getColors(){
        return (await window.axios.get(url+'style/')).data
    },
    // save colors for booking-module
    async setColors(body, jwt){
        return (await window.axios.post(url+'style/', body, {headers: {Authorization: `${jwt}`}})).data
    },

};
  
